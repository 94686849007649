<template>
    <section id="playlist_show" class="playlist_container">
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
		<div class="container-fluid">

			<button v-on:click="historyBack($router)" class="btn-standart left"><i class="el-icon el-icon-back"></i>{{ $t('global.view_all_playlists') }}</button>

			<h1>{{ $t('global.one_playlist') }} "{{ getPlaylistName() }}"</h1>

			<div class="row">
				<div class="col-md-3">
					<div class="album-block">
						<div class="album-block_img" :style="img_section_style">
							<div class="album-block_img_serv">
								&nbsp;
							</div>
						</div>
					</div>
					<br>
					<div style="text-align:left">{{ getPlaylistDescription() }} </div><br>
				</div>
				<div class="col-md-9">
					<div class="tracks-list-header-block">
						<div class="col-icon"></div>
						<div>{{ $t('track.title') }}</div>
						<div class="col-bpm">{{ $t('track.bpm') }}</div>
						<div class="col-time">{{ $t('track.time') }}</div>
						<div class="col-download">&nbsp;</div>
					</div>

					<div>
						<div v-for="track in playlist.tracks" :key="track.track_id" v-bind:id="'track_block_' + track.track_id" v-bind:data-tid="track.track_id" :class="isPremiumTrack(track)">
							<div class="track-block">
								<div class="track-block_icon" v-bind:id="'track_play_btn_' + track.track_id" v-on:click="playTrack(track)" :style="getImgUrl(config.albumCoversServer, track.label_fk, track.album_num)"></div>
								<div class="track-block_title" v-on:click="playTrack(track)">
									<strong>{{ track.primary_title }} <span>{{ track.composers }}</span></strong>
									<p>{{ track.description }}</p>

									<p class="lab_alb_trck">
										<a target="_blank" :href="'/albums?label='+track.label_fk.toUpperCase()+'&page=1'" class="hint-top-middle" :data-hint="$t('global.all_albums_of_this_labels')">{{ $t('global.label') }}: {{ track.label_fk.toUpperCase() }}</a>
										<span>/</span>
										<a target="_blank" :href="'/albums/'+track.label_fk+'/'+track.album_num" class="hint-top-middle" :data-hint="$t('global.open_album')">{{ $t('global.album') }}: {{ track.album_num.toUpperCase() }}</a>
										<span>/</span>
										<span>{{ $t('global.track') }}:</span> {{ track.track_num }}
									</p>

									<p class="dates_secondary_info">
										<span><strong>{{ $t('track.added') }}:</strong> {{ track.created }}</span>
										<template v-if="track.album_published">
											<span><strong>{{ $t('track.published') }}:</strong> {{ track.album_published }}</span>
										</template>
										<template v-if="track.bpm>0">
											<span><strong>{{ $t('track.bpm') }}:</strong> {{ track.bpm }}</span>
										</template>
										<template v-if="track.tempo">
											<span><strong>{{ $t('track.tempo') }}:</strong> {{ track.tempo }}</span>
										</template>
									</p>
								</div>
								<div class="track-block_versions">
									&nbsp;
								</div>
								<div class="track-block_stems">
									&nbsp;
								</div>
								<div class="track-block_bpm">{{ track.bpm }}</div>
								<div class="track-block_time">{{ track.duration }}</div>

								<div class="track-block_ico hint-top-middle" data-hint="Find similar">
									<a v-bind:href="'/tracks?similar='+track.track_id" target="_blank"><i class="icon-btn-similar" style="margin-top: 6px;"></i></a>
								</div>


								<div class="track-block_ico hint-top-middle" :data-hint="$t('global.favorite')">
									<template v-if="$auth.check()">
										<i v-on:click="setFavoriteStatus(track, $event)" v-bind:id="'track_block_favico_' + track.track_id" :class="getFavoriteStatus(track)"></i>
									</template>&nbsp;
								</div>
								<div class="track-block_download">
									<template v-if="$auth.check()">
										<i v-on:click="getFileLink(track.label_fk, track.album_num, track.track_num)" target="_blank" class="icon-btn-download"></i>
									</template>
									<template v-else>
										<a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
									</template>
								</div>





							</div>
						</div>
					</div>



				</div>
			</div>
		</div>
		<Footer></Footer>
    </section>
</template>

<script>
	import Vue from 'vue';
    import playlistApi from "../api"
	import axios from 'axios';
	import VueCookie from 'vue-cookie'
	Vue.use(VueCookie);
	import Footer from "../../../core/components/Footer";
    import ConfirmDialogue from "../../../core/components/ConfirmDialogue.vue";

    export default {
        name: "PlaylistView",
        components: {Footer,ConfirmDialogue},
        data() {
            return {
                playlist: {
					id: null
				},
				lang: Vue.config.lang,
				music_server: config.musicServer,
				album_covers_server: config.albumCoversServer,
            }
        },
        methods: {
            async fetchPlaylist() {
                const response = await playlistApi.find(this.$route.params.id)
                this.playlist = response.data;
            },
			historyBack(router) {
				if (window.history.length <= 1) {
					router.push({path:'/playlists'})
					return false
				} else {
					router.go(-1)
				}

			},
			getPlaylistName() {
				if (Vue.config.lang === "en") {
					return this.playlist.name;
				}
				else {
					return this.playlist.name_ru;
				}
			},
			getPlaylistDescription() {
				if (Vue.config.lang === "en") {
					return this.playlist.description;
				}
				else {
					return this.playlist.description_ru;
				}
			},
			getImgUrl(server,label,album) {
				return "background-image:url('"+server+''+label.toLowerCase()+'/'+album.toLowerCase()+".jpg')";
			},

			getFavoriteStatus(trackObj) {
				let ftc = VueCookie.get('favorite_tracks');
				let favorite_tracks = [];
				if (ftc!==undefined && ftc!==null && ftc!=="") {
					favorite_tracks = ftc.split(',');
				}

				if (favorite_tracks.indexOf(trackObj.track_id+"")>-1) {
					return 'icon-btn-favorite-active';
				}
				else {
					return 'icon-btn-favorite';
				}
			},
			setFavoriteStatus(trackObj, iconObj) {
				iconObj.currentTarget.className = "icon-btn-favorite-changing";
				axios.get('/user_like_track',
						{
							params: {track_id: trackObj.track_id},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						this.$cookie.set('favorite_tracks', response.data.favorite_tracks);
						$("#track_block_favico_"+response.data.last_change_id).attr('class', this.getFavoriteStatus(trackObj));
					})
					.catch(error => console.log(error))

			},
			playTrack(trackObj) {
				initialPlayer();
				let label = trackObj.label_fk;
				let album = trackObj.album_num;
				let track = trackObj.track_num;

				if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.track_id) {
					if ($("#jquery_jplayer_obj").data('isPlaying')) {
						$("#jquery_jplayer_obj").jPlayer("pause");
					}
					else {
						$("#jquery_jplayer_obj").jPlayer("play");
					}
				}
				else {
					setPlayerData(trackObj, this.album_covers_server);
					$("#jquery_jplayer_obj").jPlayer("setMedia", {
						mp3: this.music_server + 'stream_music_file.php?label='+label.toLowerCase()+'&album='+album.toLowerCase()+'&track='+track,
					}).jPlayer("play");
				}
			},
            isPremiumTrack(trackObj) {
                if (trackObj?.premium) {
                    return "track_common_block track_special_block";
                }
                return "track_common_block";
            },
			getFileLink(label, album, track) {
				axios.get('/track_download',
						{
							params: {
								l: label.toLowerCase(),
								a: album.toLowerCase(),
								t: track
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
                        if (response.data.block !== null && response.data.block == 1) {
                            alert("This option is disabled");
                            return;
                        }
                        else {
                            if (response.data.block !== null && response.data.block == 2) {
                                this.showPremiumError();
                                return;
                            }
                        }
						var fileLink = document.createElement('a');
						fileLink.href = response.data.url;
						fileLink.setAttribute('download', response.data.fname+'.mp3');
						document.body.appendChild(fileLink);
						fileLink.click();
					  })
					  .catch(error => console.log(error))
			},
            showPremiumError(err) {
                const ok = this.$refs.confirmDialogue.show({
                    title: this.$t('global.license_for_premium_track_title'),
                    message: this.$t('global.please_contact_us_premium_track'),
                    okButton:'OK'
                });
            }
        },

        mounted() {
            this.fetchPlaylist()
        },
		computed: {
            img_section_style: function(){
				if (this.playlist.id === undefined || this.playlist.id === null) {
					return;
				}
                var bgImg = config.playlistCoversServer+''+this.playlist.id+'.jpg';
                return {
                    "background-image": 'url('+bgImg+')'
                }
            },
		}
    }
</script>

<style lang="scss" scoped>
	.playlist_container {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.dates_secondary_info {
		padding-top: 4px;
		border-top: 1px solid rgba(255,255,255,0.2);
		span {
			display: inline-block;
			padding-right: 4px;
			strong {
				display: inline-block;
			}
			&::after {
				display: inline-block;
				padding-left: 8px;
				content: "|";
				opacity: 0.3;
			}
			&:last-child {
				&::after {
					display:none;
				}
			}
		}
	}
	.lab_alb_trck span {
		opacity: 0.4;
		position: relative;
	}
	.lab_alb_trck a {
		position: relative;
		border-radius: 4px;
		padding: 0 3px;
		&:hover {
			background:#000;
		}
	}
</style>
