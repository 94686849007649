<template>
    <section id="album_show" class="album_container">
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
        <!-- filters -->
		<div class="container-fluid">

			<button v-on:click="historyBack($router)" class="btn-standart left"><i class="el-icon el-icon-back"></i>{{ $t('global.view_all_albums') }}</button>

			<h1>{{ $t('global.album') }} {{ album.album_title }}</h1>


			<div class="row">
				<div class="col-md-3">
					<div class="album-block">
						<div class="album-block_img" :style="img_section_style">
							<div class="album-block_img_serv">
								&nbsp;
							</div>
						</div>
					</div>
					<br>
					<div style="text-align:left">{{ album.album_desc }} </div><br>
				</div>
				<div class="col-md-9">
					<div class="tracks-list-header-block">
						<div class="col-num">#</div>
						<div class="col-icon"></div>
						<div>{{ $t('track.title') }}</div>
						<div class="col-bpm">{{ $t('track.bpm') }}</div>
						<div class="col-time">{{ $t('track.time') }}</div>

						<div class="col-download" style='width: 156px'>&nbsp;</div>
					</div>


					<div>
						<div v-for="track in album.tracks" :key="track.track_id" v-bind:id="'track_block_' + track.primary.track_id" v-bind:data-tid="track.primary.track_id" :class="isPremiumTrack(track.primary)">

							<div class="track-block">
								<div class="track-block_num" v-on:click="playTrack(track.primary)">{{ track.primary.track_num }}</div>
								<div class="track-block_icon" v-bind:id="'track_play_btn_' + track.primary.track_id" v-on:click="playTrack(track.primary)" :style="getImgUrl(album_covers_server, album.label_fk, album.album_num)"></div>
								<div class="track-block_title" v-on:click="playTrack(track.primary)">
									<strong>{{ track.primary.primary_title }} <span>{{ track.primary.composers }}</span></strong>
									<p>{{ track.primary.description }}</p>
									<p class="dates_secondary_info">
										<span><strong>{{ $t('track.added') }}:</strong> {{ track.primary.created }}</span>
										<template v-if="track.primary.album_published">
											<span><strong>{{ $t('track.published') }}:</strong> {{ track.primary.album_published }}</span>
										</template>
										<template v-if="track.primary.bpm>0">
											<span><strong>{{ $t('track.bpm') }}:</strong> {{ track.primary.bpm }}</span>
										</template>
										<template v-if="track.primary.tempo">
											<span><strong>{{ $t('track.tempo') }}:</strong> {{ track.primary.tempo }}</span>
										</template>

									</p>
								</div>
								<div class="track-block_versions">
									<button :style="getVisible(track.versions.length)" v-on:click="showVersions($event)" class="btn-standart">{{ track.versions.length }} {{ $t('track.versions') }}</button>
								</div>
								<div class="track-block_stems">
									<button :style="getVisible(track.stems.length)" v-show="track.stems.length > 0" v-on:click="showStems($event)" class="btn-standart">{{ track.stems.length }} {{ $t('track.stems') }}</button>
								</div>

								<div class="track-block_bpm">{{ track.primary.bpm }}</div>
								<div class="track-block_time">{{ track.primary.duration }}</div>

								<div class="track-block_ico hint-top-middle" data-hint="Add to Project">
									<template v-if="$auth.check()">
										<i v-on:click="addToProject(track.primary, $event)" class="icon-btn-add-to-proj"></i>
									</template>&nbsp;
								</div>


								<div class="track-block_ico hint-top-middle" data-hint="Find similar">
									<a v-bind:href="'/tracks?similar='+track.primary.track_id" target="_blank"><i class="icon-btn-similar" style="margin-top: 6px;"></i></a>
								</div>



								<div class="track-block_ico hint-top-middle" :data-hint="$t('global.favorite')">
									<template v-if="$auth.check()">
										<i v-on:click="setFavoriteStatus(track.primary, $event)" v-bind:id="'track_block_favico_' + track.primary.track_id" :class="getFavoriteStatus(track.primary)"></i>
									</template>&nbsp;
								</div>
								<div class="track-block_download">
									<template v-if="$auth.check()">
										<i v-on:click="getFileLink(track.primary.label_fk, track.primary.album_num, track.primary.track_num)" target="_blank" class="icon-btn-download"></i>
									</template>
									<template v-else>
										<a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
									</template>
								</div>

							</div>

							<div class="track-versions-container">
								<div v-for="version_track in track.versions" :key="track.track_id">
									<div class="track-block" >
										<div class="track-block_num" v-on:click="playTrack(version_track)">{{ version_track.track_num }}</div>
										<div class="track-block_icon" v-bind:id="'track_play_btn_' + version_track.track_id" v-on:click="playTrack(version_track)" :style="getImgUrl(album_covers_server, album.label_fk, album.album_num)"></div>
										<div class="track-block_title" v-on:click="playTrack(version_track)">
											<strong>{{ version_track.primary_title }} <span>{{ version_track.composers }}</span></strong>
											<p>{{ version_track.description }}</p>
										</div>
										<div class="track-block_versions">
											&nbsp;
										</div>
										<div class="track-block_stems">
											&nbsp;
										</div>
										<div class="track-block_bpm">{{ version_track.bpm }}</div>
										<div class="track-block_time">{{ version_track.duration }}</div>

										<div class="track-block_ico hint-top-middle" data-hint="Add to Project">
											<template v-if="$auth.check()">
												<i v-on:click="addToProject(version_track, $event)" class="icon-btn-add-to-proj"></i>
											</template>&nbsp;
										</div>

										<div class="track-block_ico hint-top-middle" data-hint="Find similar">
											<a v-bind:href="'/tracks?similar='+version_track.track_id" target="_blank"><i class="icon-btn-similar" style="margin-top: 6px;"></i></a>
										</div>

										<div class="track-block_ico hint-top-middle" :data-hint="$t('global.favorite')">
											<template v-if="$auth.check()">
												<i v-on:click="setFavoriteStatus(version_track, $event)" v-bind:id="'track_block_favico_' + version_track.track_id" :class="getFavoriteStatus(version_track)"></i>
											</template>&nbsp;
										</div>


										<div class="track-block_download">
											<template v-if="$auth.check()">
												<i v-on:click="getFileLink(version_track.label_fk, version_track.album_num, version_track.track_num)" target="_blank" class="icon-btn-download"></i>
											</template>
											<template v-else>
												<a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
											</template>
										</div>


									</div>
								</div>
							</div>

							<div class="track-stems-container">
								<div v-for="stem_track in track.stems" :key="track.track_id">
									<div class="track-block">
										<div class="track-block_num" v-on:click="playTrack(stem_track)">{{ stem_track.track_num }}</div>
										<div class="track-block_icon" v-bind:id="'track_play_btn_' + stem_track.track_id" v-on:click="playTrack(stem_track)" :style="getImgUrl(album_covers_server, album.label_fk, album.album_num)"></div>
										<div class="track-block_title" v-on:click="playTrack(stem_track)">
											<strong>{{ stem_track.primary_title }} <span>{{ stem_track.composers }}</span></strong>
											<p>{{ stem_track.description }}</p>
										</div>
										<div class="track-block_versions">
											&nbsp;
										</div>
										<div class="track-block_stems">
											&nbsp;
										</div>
										<div class="track-block_bpm">{{ stem_track.bpm }}</div>
										<div class="track-block_time">{{ stem_track.duration }}</div>

										<div class="track-block_ico hint-top-middle" data-hint="Add to Project">
											<template v-if="$auth.check()">
												<i v-on:click="addToProject(stem_track, $event)" class="icon-btn-add-to-proj"></i>
											</template>&nbsp;
										</div>

										<div class="track-block_ico hint-top-middle" data-hint="Find similar">
											<a v-bind:href="'/tracks?similar='+stem_track.track_id" target="_blank"><i class="icon-btn-similar" style="margin-top: 6px;"></i></a>
										</div>

										<div class="track-block_ico hint-top-middle" :data-hint="$t('global.favorite')">
											<template v-if="$auth.check()">
												<i v-on:click="setFavoriteStatus(stem_track, $event)" v-bind:id="'track_block_favico_' + stem_track.track_id" :class="getFavoriteStatus(stem_track)"></i>
											</template>&nbsp;
										</div>
										<div class="track-block_download">
											<template v-if="$auth.check()">
												<i v-on:click="getFileLink(stem_track.label_fk, stem_track.album_num, stem_track.track_num)" target="_blank" class="icon-btn-download"></i>
											</template>
											<template v-else>
												<a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
											</template>
										</div>


									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

			</div>


		</div>


		<el-dialog
						:title="formTitle"
						:visible.sync="formVisible"
						close-on-click-modal
						class="projects-dialog">
						<TrackForm :initialForm="formData" @addTrackToProjectAction='addTrackToProjectAction' @cancel="formVisible = false" />
					</el-dialog>

		<Footer></Footer>
    </section>
</template>

<script>
	import Vue from 'vue';
    import albumApi from "../api";
	import Breadcrumbs from "../../../core/components/Breadcrumbs";
	import TrackForm from "./TrackForm";
	import axios from 'axios';

	import VueCookie from 'vue-cookie';
	Vue.use(VueCookie);
	import Footer from "../../../core/components/Footer";
    import ConfirmDialogue from "../../../core/components/ConfirmDialogue.vue";

    export default {
        name: "AlbumView",
		components: {TrackForm,Breadcrumbs,Footer, ConfirmDialogue},
        data() {
            return {
                album: {},
				album_covers_server: config.albumCoversServer,
				music_server: config.musicServer,
				formVisible: false,
				formData: null,
				formTitle: ''
            }
        },

        methods: {
            async fetchAlbum() {
                const response = await albumApi.find(this.$route.params.label, this.$route.params.album)
                this.album = response.data;
            },
			getImgUrl(server,label,album) {
				return "background-image:url('"+server+''+label.toLowerCase()+'/'+album.toLowerCase()+".jpg')";
			},
			getVisible(countVal) {
				if (countVal<1) {
					return "display:none";
				}
				else {
					return '';
				}
			},
			playTrack(trackObj) {
				initialPlayer();
				let label = trackObj.label_fk;
				let album = trackObj.album_num;
				let track = trackObj.track_num;

				if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.track_id) {
					if ($("#jquery_jplayer_obj").data('isPlaying')) {
						$("#jquery_jplayer_obj").jPlayer("pause");
					}
					else {
						$("#jquery_jplayer_obj").jPlayer("play");
					}
				}
				else {
					setPlayerData(trackObj, this.album_covers_server);
					$("#jquery_jplayer_obj").jPlayer("setMedia", {
						mp3: this.music_server + 'stream_music_file.php?label='+label.toLowerCase()+'&album='+album.toLowerCase()+'&track='+track,
					}).jPlayer("play");
				}
			},
			getFileLink(label, album, track) {
				axios.get('/track_download',
						{
							params: {
								l: label.toLowerCase(),
								a: album.toLowerCase(),
								t: track
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
                        if (response.data.block !== null && response.data.block == 1) {
							alert("This option is disabled");
							return;
						}
                        else {
                            if (response.data.block !== null && response.data.block == 2) {
                                this.showPremiumError();
                                return;
                            }
                        }
						var fileLink = document.createElement('a');
						fileLink.href = response.data.url;
						fileLink.setAttribute('download', response.data.fname+'.mp3');
						document.body.appendChild(fileLink);
						fileLink.click();
					  })
					  .catch(error => console.log(error))
			},
            showPremiumError(err) {
                const ok = this.$refs.confirmDialogue.show({
                    title: this.$t('global.license_for_premium_track_title'),
                    message: this.$t('global.please_contact_us_premium_track'),
                    okButton:'OK'
                });
            },
			historyBack(router) {
				if (window.history.length <= 1) {
					router.push({path:'/albums'})
					return false
				} else {
					router.go(-1)
				}

			},
            isPremiumTrack(trackObj) {
                if (trackObj?.premium) {
                    return "track_common_block track_special_block";
                }
                return "track_common_block";
            },
			showVersions(e) {
				e.preventDefault();
				e.stopPropagation();
				if ($(e.target.parentNode.parentNode.parentNode).hasClass('open_versions')) {
					$(e.target.parentNode.parentNode.parentNode).removeClass('open_versions');
					$(e.target.parentNode.parentNode.parentNode).removeClass('open_stems');
				}
				else {
					$(e.target.parentNode.parentNode.parentNode).addClass('open_versions');
					$(e.target.parentNode.parentNode.parentNode).removeClass('open_stems');
				}
			},
			showStems(e) {
				e.preventDefault();
				e.stopPropagation();

				if ($(e.target.parentNode.parentNode.parentNode).hasClass('open_stems')) {
					$(e.target.parentNode.parentNode.parentNode).removeClass('open_versions');
					$(e.target.parentNode.parentNode.parentNode).removeClass('open_stems');
				}
				else {
					$(e.target.parentNode.parentNode.parentNode).addClass('open_stems');
					$(e.target.parentNode.parentNode.parentNode).removeClass('open_versions');
				}

			},
			getFavoriteStatus(trackObj) {
				let ftc = VueCookie.get('favorite_tracks');
				let favorite_tracks = [];
				if (ftc!==undefined && ftc!==null && ftc!=="") {
					favorite_tracks = ftc.split(',');
				}

				if (favorite_tracks.indexOf(trackObj.track_id+"")>-1) {
					return 'icon-btn-favorite-active';
				}
				else {
					return 'icon-btn-favorite';
				}
			},
			setFavoriteStatus(trackObj, iconObj) {
				iconObj.currentTarget.className = "icon-btn-favorite-changing";
				axios.get('/user_like_track',
						{
							params: {track_id: trackObj.track_id},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						this.$cookie.set('favorite_tracks', response.data.favorite_tracks);
						$("#track_block_favico_"+response.data.last_change_id).attr('class', this.getFavoriteStatus(trackObj));
					})
					.catch(error => console.log(error))

			},
			addTrackToProjectAction(data) {
				axios.get('/add_to_project',
						{
							params: data,
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
					})
					.catch(error => console.log(error))

				this.formVisible = false
			},
			addToProject(track,eventIcon) {
				this.formTitle = 'Add to Project';
                this.formData = Object.assign({}, {
					track_id: track.track_id,
				});
                this.formVisible = true;
			},
        },
        mounted() {
            this.fetchAlbum()
        },
		computed: {
            img_section_style: function(){
				if (this.album.label_fk === undefined) {
					return;
				}
                var bgImg = this.album_covers_server+''+this.album.label_fk.toLowerCase()+'/'+this.album.album_num.toLowerCase()+'.jpg';
                return {
                    "background-image": 'url('+bgImg+')'
                }
            },
		}
    }
</script>

<style lang="scss" scoped>
	.album_container {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.dates_secondary_info {
		padding-top: 4px;
		border-top: 1px solid rgba(255,255,255,0.2);
		span {
			display: inline-block;
			padding-right: 4px;
			strong {
				display: inline-block;
			}
			&::after {
				display: inline-block;
				padding-left: 8px;
				content: "|";
				opacity: 0.3;
			}
			&:last-child {
				&::after {
					display:none;
				}
			}
		}
	}
	.lab_alb_trck span {
		opacity: 0.4;
		position: relative;
	}
	.lab_alb_trck a {
		position: relative;
		border-radius: 4px;
		padding: 0 3px;
		&:hover {
			background:#000;
		}
	}
</style>
