import enLocale from 'element-ui/lib/locale/lang/en'

export const messages = {
    global: {
        ok: 'Ok',
        cancel: 'Cancel',
		loading: 'Loading...',
		yes: 'Yes',
        save: 'Save',
		copy_link: 'Copy link',
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        new: 'New',
        search: 'Search',
		premium: 'Premium tracks',
		albums: 'Albums',
        latest_albums: 'Latest Albums',
        latest_alibi_albums: 'Latest ALIBI MUSIC Albums',
		tracks: 'Tracks',
		show_all: 'show all',
		press_enter_for_more_results: 'Press ENTER for more results',
		tags: 'Tags',
		sound_effects: 'Sound Effects',
		album: 'Album',
		projects: 'Projects',
		project: 'Project',
		select_label: "Select Label",
		find_the_album: "Find Album",
		find_the_track: "Find Track",
		sfx: 'SFX',
		playlist: 'Playlists',
		one_playlist: 'Playlist',
		view_all_albums: 'View All Albums',
		view_all_playlists: 'View All Playlists',
		tracks_amount: 'tracks',
		results: 'results',
        unknown_server_error: 'Unknown server error',
		error: 'Error',
		label: 'Label',
		track: 'Track',
		all_albums_of_this_labels: 'All albums of this label',
		open_album: 'Open album',
		open_track: 'Open track',
		login_to_download_a_track: 'Please login to download a track',
		clear_all: 'Clear All',
		favorite: 'Favorite',
		sec: 'sec',
		min: 'min',
		max: 'Max',
		email_us: 'Email Us',
		technical_support: 'Technical Support',
		about_apollomusic: 'About Apollomusic',
		our_projects: 'Our Projects',
		return_to_search: 'Return to search',
        There_are_no_results_that_match_your_search: 'There are no results that match your search',
        license_for_the_ALIBI_catalog_title: 'License for the ALIBI catalog',
        please_contact_us_alibi: 'Please contact us (<a style="text-decoration: underline" href="mailto:jelena@apollomedia.pro">jelena@apollomedia.pro</a>) to clarify the rules for using this catalog',
        license_for_premium_track_title: 'License for the premium collection',
        please_contact_us_premium_track: 'Dear customer! This track belongs to our premium collection. For all licensing questions, please contact: <a style="text-decoration: underline" href="mailto:jelena@apollomedia.pro">jelena@apollomedia.pro</a>',

        form: {
            rules: {
                required: 'Field "{fieldName}" is required',
                email: 'Please input correct email address',
                min: 'Field length "{fieldName}" must be more then {attribute} characters',
                max: 'Field length "{fieldName}" must be less then {attribute} characters',
                password_repeat: {
                    different: 'Mismatch passwords',
                }
            }
        },
        Clear_listened: 'Clear listened',
    },
	track: {
		title: 'Title',
		bpm: 'BPM',
		time: 'Time',
		versions: 'Versions',
		stems: 'Stems',
		download: 'Download',
		added: 'Added',
		tempo: 'Tempo',
		downloaded: 'Downloaded',
		published: 'Published'
	},
	project: {
		download_project: 'Download Project',
		share_project: 'Share Project',
		project_is_shared: 'Project is shared',
		cancel_sharing_text: 'This project is opened and available via this sharing link',
		cancel_sharing: 'Cancel sharing',
		copy_link: 'Copy Link',
		errors: {
			project_size_max: 'You can download project wuth 100 tracks max!',
			project_size_min: 'There is no tracks to download!',
		}
	},
	sort: {
		by: 'Sort By',
		sort: 'Sort',
		relevance: 'Relevance',
        title: 'Title',
        album_title: 'Album title',
		created: 'Added',
		added: 'Added',
		downloads: 'Downloads',
		high_to_low: 'High to Low',
		low_to_high: 'Low to High',
		reset: 'Reset',
		published: 'Published',
		bpm: 'BPM',
		length: 'Length',
		random: 'Random',
	},
    auth: {
		create_an_account: 'Create an Account',
        token_expired_alert_title: 'Session Expired!',
        token_expired_alert_text: 'Please log in again to continue.',
		forgot_password: 'Forgot password',
		invalid_login_credentials: 'Invalid login credentials.',
        verification: {
            resend_link: 'Resend verification',
            resend_title: 'Resend verification',
            resend_button: 'Send',
            failed: 'The verification link is invalid.',
        },
        resend_verification_link: 'Resend verification email',
        login: {
            title: 'Login',
            submit_button: 'Log In',
            email_label: 'Email',
            password_label: 'Password',
        },
        logout: {
            title: 'Logout',
        },
        register: {
            title: 'Register',
            name_label: 'Name',
            email_label: 'Email',
            password_label: 'Password',
            password_confirmation_label: 'Repeat password',
            submit_button: 'Sign Up',
            success: 'Thanks for registration.',
			sign_up: 'Sign Up',
        },
        logout_confirm: {
            title: 'Confirm LogOut',
            text: 'You will be logged out',
            button_ok: 'Ok',
            button_cancel: 'Cancel',
        }
    },
    setting: {
        profile: {
            name: 'Name',
            email: 'Email',
        }
    },
	dashboard: {
		title: 'Dashboard',
		favorites: 'Favorites',
		my_projects: 'My Projects',
		downloads: 'Downloads',
		clear_all: 'Clear All',
		clear_all_confirm: 'Are you sure you want to clear this list?',
		copy_to_playlist: 'Copy to Playlist'
	},
	filter: {
		genre: 'Genre',
		mood: 'Mood',
		instruments: 'Instrument',
		topic: 'Topic',
		performance: 'Performance',
		advanced: 'Advanced',
		with_stems: 'With stems',
		vocal: 'Vocal',
		include: 'Include',
		exclude: 'Exclude',
		find_with_this_tempo: 'Find with this tempo',
		label: 'Label',
		song_length: 'Song Length',
		tempo: 'Tempo',
		bpm: 'BPM',
		era: 'Era',
		similar_track: 'Similar Track',
		search_within: 'Search Within',
		track_title: 'Track title',
		description_and_tags: 'Description & Tags',
		composer: 'Composer',
		with_stems_only: 'With stems only',
		allows_to_limit_search_fields: 'Allows you to limit search fields',
        music_tracks: 'Music Tracks',
        sound_fx: 'Sound FX',
        game_loops: 'Game Loops',

		tempos: {
			very_fast: 'Very Fast',
			fast: 'Fast',
			medium_fast: 'Medium Fast',
			medium: 'Medium',
			medium_slow: 'Medium Slow',
			slow: 'Slow',
			very_slow: 'Very Slow'
		}
	},
    ...enLocale
}
