<template>
	<section id="track" class="track_search_list_container">
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
		<div class="track_list_filter_block" style="height:calc(100vh - 145px)">
				<div class="track_list_filter_block_main">
					<ul>
						<li ref="filters_main_genres"><a v-on:click.prevent="showFilter('genres', false)">{{ $t('filter.genre') }}</a></li>
						<li ref="filters_main_moods"><a v-on:click.prevent="showFilter('moods', false)">{{ $t('filter.mood') }}</a></li>
						<li ref="filters_main_instruments"><a v-on:click.prevent="showFilter('instruments', false)">{{ $t('filter.instruments') }}</a></li>
						<li ref="filters_main_topic"><a v-on:click.prevent="showFilter('topic', false)">{{ $t('filter.topic') }}</a></li>
						<li ref="filters_main_performance"><a v-on:click.prevent="showFilter('performance', false)">{{ $t('filter.performance') }}</a></li>
						<li ref="filters_main_labels"><a v-on:click.prevent="showFilter('labels', false)">{{ $t('filter.label') }}</a></li>
						<li ref="filters_main_advanced"><a v-on:click.prevent="showFilter('advanced', false)">{{ $t('filter.advanced') }}</a></li>
						<li ref="filters_main_era"><a v-on:click.prevent="showFilter('era', false)">{{ $t('filter.era') }}</a></li>
					</ul>
				</div>
				<div class="track_list_filter_block_sub">
					<div class="track_list_filter_block_sub_cnt" style="height: calc(100vh - 145px); ">
						<div class="track_list_filter_genres_list">
							<ul>
								<li v-for="(subTree, keyword) in keywords.genres"><div v-on:click.stop="addKeyword(keyword)">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
										<span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
										<p>{{ keyword }}</p>
									</div>
									<ul v-if="subTree">
										<li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
												<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
												<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
												<p>{{ keyword2 }}</p>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>


						<div class="track_list_filter_moods_list">
							<ul>
								<li v-for="(subTree, keyword) in keywords.moods"><div v-on:click.stop="addKeyword(keyword)">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
										<span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
										<p>{{ keyword }}</p>
									</div>
									<ul v-if="subTree">
										<li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
												<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
												<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
												<p>{{ keyword2 }}</p>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>


						<div class="track_list_filter_instruments_list">
							<ul>
								<li v-for="(subTree, keyword) in keywords.instruments"><div v-on:click.stop="addKeyword(keyword)">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
										<span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
										<p>{{ keyword }}</p>
									</div>
									<ul v-if="subTree">
										<li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
												<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
												<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
												<p>{{ keyword2 }}</p>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>


						<div class="track_list_filter_topic_list">
							<ul>
								<li v-for="(subTree, keyword) in keywords.topic"><div v-on:click.stop="addKeyword(keyword)">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
										<span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
										<p>{{ keyword }}</p>
									</div>
									<ul v-if="subTree">
										<li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
												<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
												<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
												<p>{{ keyword2 }}</p>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>


						<div class="track_list_filter_performance_list">
							<ul>
								<li v-for="(subTree, keyword) in keywords.performance"><div v-on:click.stop="addKeyword(keyword)">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword)"></i>
										<span v-if="notEmptyTree(subTree)" v-on:click.stop="openLevel"></span>
										<p>{{ keyword }}</p>
									</div>
									<ul v-if="subTree">
										<li v-for="(subTree2, keyword2) in subTree" v-on:click.stop="addKeyword(keyword2)"><div>
												<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addKeyword(keyword2)"></i>
												<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeKeyword(keyword2)"></i>
												<p>{{ keyword2 }}</p>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>


						<div class="track_list_filter_labels_list">
							<ul>
								<li v-for="label in labels"><div v-on:click.stop="addLabel(label.label)" class="hint-top-middle" :data-hint="label.publisher">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addLabel(label.label)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeLabel(label.label)"></i>
										<p>{{ label.label }} - <u>{{ label.publisher }}</u></p>
									</div>
								</li>
							</ul>
						</div>


						<div class="track_list_filter_advanced_list">
							<div class="filter_advanced_list__container">
								<p class="filter_advanced_list__container-title">{{ $t('filter.song_length') }} <span>({{ showCurrentTime() }})</span></p>
								<div class="filter_advanced_list__container-slider">
									<vue-slider v-model="length" :tooltip-formatter="val => formatAsTime(val)" :lazy="true" :max="1200"></vue-slider>
								</div>
								<br>
								<br>
								<p class="filter_advanced_list__container-title">BPM <span>({{ bpm[0] }} - {{ bpm[1] }})</span></p>
								<div class="filter_advanced_list__container-slider">
									<vue-slider v-model="bpm" :lazy="true" :max="200"></vue-slider>
								</div>
								<br><br>
								<p class="filter_advanced_list__container-title">{{ $t('filter.tempo') }}</p>

							</div>
								<ul>
									<li :class="isActiveOption('very_fast', filters.tempo, ',')"><div v-on:click.stop="switchTempo('very_fast')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
										<p>{{ $t('filter.tempos.very_fast') }}</p>
										</div>
									</li>
									<li :class="isActiveOption('fast', filters.tempo, ',')"><div v-on:click.stop="switchTempo('fast')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
										<p>{{ $t('filter.tempos.fast') }}</p>
										</div>
									</li>
									<li :class="isActiveOption('medium_fast', filters.tempo, ',')"><div v-on:click.stop="switchTempo('medium_fast')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
										<p>{{ $t('filter.tempos.medium_fast') }}</p>
										</div>
									</li>
									<li :class="isActiveOption('medium', filters.tempo, ',')"><div v-on:click.stop="switchTempo('medium')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
										<p>{{ $t('filter.tempos.medium') }}</p>
										</div>
									</li>
									<li :class="isActiveOption('medium_slow', filters.tempo, ',')"><div v-on:click.stop="switchTempo('medium_slow')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
										<p>{{ $t('filter.tempos.medium_slow') }}</p>
										</div>
									</li>
									<li :class="isActiveOption('slow', filters.tempo, ',')"><div v-on:click.stop="switchTempo('slow')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
										<p>{{ $t('filter.tempos.slow') }}</p>
										</div>
									</li>
									<li :class="isActiveOption('very_slow', filters.tempo, ',')"><div v-on:click.stop="switchTempo('very_slow')" :data-hint="$t('filter.find_with_this_tempo')" class="hint-top-middle">
										<p>{{ $t('filter.tempos.very_slow') }}</p>
										</div>
									</li>

								</ul>
						</div>


						<div class="track_list_filter_era_list">
							<ul>
								<li v-for="era in erasList" :class="isActiveOption(era, filters.era, ',')"><div v-on:click.stop="switchEra(era)" class="hint-top-middle">
										<i class="add_word hint-top-right" :data-hint="$t('filter.include')" v-on:click.stop="addEra(era)"></i>
										<i class="exclude_word hint-top-right" :data-hint="$t('filter.exclude')" v-on:click.stop="excludeEra(era)"></i>
										<p>{{ era }}s</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
		</div>


		<div class="track_list_root">
            <div class="loader_bgnd">
                <div class="loader_bgnd_cont">
                    <div class="loader triangle">
                        <svg viewBox="0 0 86 80">
                            <polygon points="43 8 79 72 7 72"></polygon>
                        </svg>
                    </div>
                </div>
            </div>
			<div class="track_list_root_cont" style="height: calc(100vh - 145px);">

				<div id="overlay-block" v-on:click.stop="closeSuggest()"></div>
				<TrackSearchForm
					ref="search_form"
					:close_suggest="close_suggest"
					@suggestClosed='suggestClosed'
					@setStems='setStems'
					@setSearchWithin='setSearchWithin'
					@setSearchText='setSearchText'></TrackSearchForm>



                <template v-if="searchIsEmpty">
                    <div class="container-fluid">
                        <h1>{{ $t('global.latest_albums') }}</h1>
                        <div class="main-page-album__wrapper">
                            <div class="row">
                                <div v-for="album in latestAlbums" class="col-md-3 col-lg-3 col-sm-6 col-xl-2">
                                    <div class="main-page-album">
                                        <router-link :to="{name: 'Show Album', params: {label: album.label_fk, album: album.album_num}}">
                                            <div class="main-page-album_image"><img :src="getAlbumImage(album)"></div>
                                            <span>{{ Math.max(album.tracks_amount, album.main_tracks_amount) }} {{ $t('global.tracks_amount') }}</span>
                                            <strong><p>{{ album.album_title }}</p></strong>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="searchIsEmpty === false">
                    <div class="container-fluid">
                        <template v-if="showClearListenedButton">
                            <button
                                style="margin-left: 8px; margin-top: -1px;"
                                v-on:click="removeListenedTracks()"
                                class="button-classic float-right">{{ $t('global.Clear_listened') }}</button>
                        </template>
                        <h1>{{ $t('global.tracks') }}</h1>
                        <TrackFilters
                            @removeLabel='removeLabel'
                            @removeEra='removeEra'
                            @removeSimilar='removeSimilar'
                            @removeTempo='removeTempo'
                            :sample="sample"></TrackFilters>

                        <div class="row">
                            <div class="col-md-4 search-results-info">
                                <span v-html="getImgSearchResultInfo()"></span>
                            </div>
                            <div class="col-md-8 search-order-container"><TrackOrderBlock @setOrder='setOrder'></TrackOrderBlock></div>
                        </div>
                    </div>
                </template>


                <template v-if="searchIsEmpty === false">
                    <el-pagination
                        layout="prev, pager, next"
                        :current-page.sync="page"
                        :page-size.sync="globalPageSize"
                        :total="tracksMeta.total"
                        class="pagination-padding">
                    </el-pagination>


                    <div class="container-fluid">
                        <div class="tracks-list-header-block">
                            <div class="col-icon"></div>
                            <div>{{ $t('track.title') }}</div>
                            <div class="col-bpm">{{ $t('track.bpm') }}</div>
                            <div class="col-time">{{ $t('track.time') }}</div>
                            <div class="col-download">&nbsp;</div>
                            <div class="col-download">&nbsp;</div>
                        </div>

                        <div>
                            <div v-for="track in tracks" :key="track.track_id" v-bind:id="'track_block_' + track.track_id" v-bind:data-tid="track.track_id" :class="isPremiumTrack(track)" :style="isPlackPlayed(track)">
                                <div class="track-block" >
                                    <div class="track-block_icon" v-bind:id="'track_play_btn_' + track.track_id" v-on:click="playTrack(track)" :style="getImgUrl(album_covers_server, track.label_fk, track.album_num)"></div>
                                    <div class="track-block_title">
                                        <strong>{{ track.primary_title }} <span>{{ track.composers }}</span></strong>
                                        <p>{{ track.description }}</p>
                                        <p class="lab_alb_trck">
                                            <a target="_blank" :href="'/albums?label='+track.label_fk.toUpperCase()+'&page=1'" class="hint-top-middle" :data-hint="$t('global.all_albums_of_this_labels')">{{ $t('global.label') }}: {{ track.label_fk.toUpperCase() }}</a>
                                            <span>/</span>
                                            <a target="_blank" :href="'/albums/'+track.label_fk+'/'+track.album_num" class="hint-top-middle" :data-hint="$t('global.open_album')">{{ $t('global.album') }}: {{ track.album_num.toUpperCase() }}</a>
                                            <span>/</span>
                                            <a target="_blank" :href="'/tracks/'+track.label_fk+'/'+track.album_num+'/'+track.track_num"  class="hint-top-middle" :data-hint="$t('global.open_track')">{{ $t('global.track') }}: {{ track.track_num }}</a>
                                        </p>
                                        <p class="dates_secondary_info">
                                            <span><strong>{{ $t('track.added') }}:</strong> {{ track.created }}</span>
                                            <template v-if="track.album_published">
                                                <span><strong>{{ $t('track.published') }}:</strong> {{ track.album_published }}</span>
                                            </template>
                                            <template v-if="track.bpm>0">
                                                <span><strong>{{ $t('track.bpm') }}:</strong> {{ track.bpm }}</span>
                                            </template>
                                            <template v-if="track.tempo">
                                                <span><strong>{{ $t('track.tempo') }}:</strong> {{ track.tempo }}</span>
                                            </template>
                                        </p>
                                    </div>
                                    <div class="track-block_versions">
                                        <button :style="getVisible(track.versions)" v-on:click="showTracksVersions(track.label_fk, track.album_num, track.track_num, track.track_id)" class="btn-standart">{{ track.versions }} {{ $t('track.versions') }}</button>
                                    </div>
                                    <div class="track-block_stems">
                                        <button :style="getVisible(track.stems)" v-on:click="showTracksStems(track.label_fk, track.album_num, track.track_num, track.track_id)" class="btn-standart">{{ track.stems }} {{ $t('track.stems') }}</button>
                                    </div>

                                    <div class="track-block_bpm">{{ track.bpm }}</div>
                                    <div class="track-block_time">{{ track.duration }}</div>


                                    <div class="track-block_ico hint-top-middle" data-hint="Find similar">
                                        <i v-on:click="showSimilarTracks(track)" class="icon-btn-similar"></i>
                                    </div>
                                    <div class="track-block_ico hint-top-middle" data-hint="Add to Project">
                                        <template v-if="$auth.check()">
                                            <i v-on:click="addToProject(track, $event)" class="icon-btn-add-to-proj"></i>
                                        </template>&nbsp;
                                    </div>


                                    <div class="track-block_ico hint-top-middle" :data-hint="$t('global.favorite')">
                                        <template v-if="$auth.check()">
                                            <i v-on:click="setFavoriteStatus(track, $event)" v-bind:id="'track_block_favico_' + track.track_id" :class="getFavoriteStatus(track)"></i>
                                        </template>&nbsp;
                                    </div>

                                    <div class="track-block_download">
                                        <template v-if="$auth.check()">
                                            <i v-on:click="getFileLink(track.label_fk, track.album_num, track.track_num)" target="_blank" class="icon-btn-download"></i>
                                        </template>
                                        <template v-else>
                                            <a href="/login" style="opacity:1; position: relative" class="hint-top-right-s-small icon-btn-download" :data-hint="$t('global.login_to_download_a_track')"></a>
                                        </template>
                                    </div>

                                </div>
                                <TrackVersions
                                    v-bind:ref="'track_cnt_' + track.track_id"
                                    @addToProject='addToProject'
                                    @showSimilarTracks='showSimilarTracks'
                                    ></TrackVersions>

                            </div>

                        </div>
                    </div>



                    <el-pagination
                        layout="prev, pager, next"
                        :current-page.sync="page"
                        :page-size.sync="globalPageSize"
                        :total="tracksMeta.total"
                        class="pagination-padding">
                    </el-pagination>
                </template>


				<el-dialog
						:title="formTitle"
						:visible.sync="formVisible"
						close-on-click-modal
						class="projects-dialog">
						<TrackForm :initialForm="formData" @addTrackToProjectAction='addTrackToProjectAction' @cancel="formVisible = false" />
					</el-dialog>

				<Footer></Footer>
			</div>
		</div>







	</section>
</template>

<script>
	import Vue from 'vue';
	import axios from 'axios';
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import {TRACK_FETCH, TRACK_OBTAIN} from "../store/types";
    import trackApi from '../api'

	import TrackForm from "./TrackForm";
	import TrackSearchForm from "./TrackSearchForm";
	import TrackFilters from "./TrackFilters";
	import TrackOrderBlock from "./TrackOrderBlock";
	import Footer from "../../../core/components/Footer";
	import TrackVersions from "./TrackVersions";

	import router from '../../../bootstrap/router';
	import VueCookie from 'vue-cookie';
	import VueSlider from 'vue-slider-component';
    import ConfirmDialogue from "../../../core/components/ConfirmDialogue.vue";

	Vue.use(VueCookie)

    export default {
        name: "TrackList",
        components: {
            ConfirmDialogue,
            TrackForm,TrackSearchForm,TrackFilters,TrackOrderBlock,Footer,TrackVersions,VueSlider},
        data: () => ({
			close_suggest: false,
			sort: 'created',
			order: 'desc',
            filters: {
                search: '',
				label: '',
				era: '',
				tempo: '',
            },
            page: 1,
			globalPageSize: 30,
			album_covers_server: config.albumCoversServer,
			music_server: config.musicServer,
            showClearListenedButton: false,
			keywords: {
				genres: null,
				moods: null,
				instruments: null,
				topic: null,
				performance: null,
				advanced: null
			},
			labels: [],
			erasList: [1700,1800,1900,1910,1920,1930,1940,1950,1960,1970,1980,1990,2000,2010,2020],
			similar: null,
			lang: Vue.config.lang,
			activeFilter: null,
			length: [0, 1200],
			bpm: [0,200],
			stems: 0,
			search_within: '',
			enableWatchers: false,
			formVisible: false,
			formData: null,
			formTitle: '',
			sf: null,
            previous_track: null,
            searchIsEmpty: true,
            latestAlbums: []
        }),
        computed: {
            ...mapGetters(['tracks', 'sample', 'tracksMeta', 'tracksLoading']),
        },
        created() {
            for (var i = 0; i<localStorage.length; i++) {
                if (localStorage.key(i).indexOf("track-") > -1) {
                    this.showClearListenedButton = true;
                }
            }

			if (!this.$auth.check()) {
				window.location = '/login';
			}
			else {
                this.cleanListenedTracks();
				this.filters.label = this.$route.query.label;
				this.filters.search = this.$route.query.search;
				this.filters.era = this.$route.query.era;
				this.filters.tempo = this.$route.query.tempo;

				this.sort = this.$route.query.sort;
				this.order = this.$route.query.order;
				this.stems = this.$route.query.stems;
				this.search_within = this.$route.query.search_within;
				this.similar = this.$route.query.similar;
				this.page = parseInt(this.$route.query.page);
				this.globalPageSize = 30;
				this.sf = this.$route.query.sf;
				let bpmMin = parseInt(this.$route.query.bpm_min);
				let bpmMax = parseInt(this.$route.query.bpm_max);
				if (isNaN(bpmMin)) {
					this.bpm[0] = 0;
				}
				else {
					this.bpm[0] = bpmMin;
				}
				if (isNaN(bpmMax)) {
					this.bpm[1] = 200;
				}
				else {
					this.bpm[1] = bpmMax;
				}

				let secMin = parseInt(this.$route.query.sec_min);
				let secMax = parseInt(this.$route.query.sec_max);
				if (isNaN(secMin)) {
					this.length[0] = 0;
				}
				else {
					this.length[0] = secMin;
				}
				if (isNaN(secMax)) {
					this.length[1] = 1200;
				}
				else {
					this.length[1] = secMax;
				}

				this.fetchData();
				this.enableWatchers = true;
			}
        },
		beforeCreate() {
			this.keywords = Vue.config.keywordsCache;
			this.labels = Vue.config.labelsCache;
		},
		mounted() {
			this.showFilter(this.$cookie.get('filter'), true);
			this.resetBpmLinkControl();
		},
        watch:{
            tracksLoading: function()
            {
                if (!this.tracksLoading) {
                    $('.loader_bgnd').fadeOut(300);
                }
            },
			tracksMeta: function() {
				this.sf = this.tracksMeta.sf;
			},
            page: function () {
				if (!this.enableWatchers) { return; }
                this.setURL();
            },
			lang: function() {

			},
			keywords: function() {
				if (!this.enableWatchers) { return; }
				if (this.keywords !== undefined && this.keywords.genres !== null) {
					Vue.config.keywordsCache = this.keywords;
				}
			},
			labels: function() {
				if (!this.enableWatchers) { return; }
				if (this.labels !== undefined && this.labels !== null) {
					Vue.config.labelsCache = this.labels;
				}
			},
			bpm: function() {
				if (!this.enableWatchers) { return; }
				this.resetBpmLinkControl();
				this.setURL();
			},
			length: function() {
				if (!this.enableWatchers) { return; }
				this.setURL();
			},
        },
        methods: {
            ...mapActions([TRACK_FETCH]),
            ...mapMutations([TRACK_OBTAIN]),

            fetchData() {
				if (!this.$auth.check()) {
					return;
				}
                let params = {
                    page: this.page,
                    search: this.filters.search,
					label: this.filters.label,
                    sort: this.sort,
					order: this.order,
					bpm_min: this.bpm[0],
					bpm_max: this.bpm[1],
					sec_min: this.length[0],
					sec_max: this.length[1],
                    pageSize: this.globalPageSize,
					era: this.filters.era,
					tempo: this.filters.tempo,
					similar: this.similar,
					stems: this.stems,
					search_within: this.search_within,
					sf: this.sf
                };
                if (
                    this.filters.search ||
                    this.filters.label ||
                    this.filters.era ||
                    this.filters.tempo ||
                    this.similar
                ) {
                    this.searchIsEmpty = false;
                }
                else {
                    this.searchIsEmpty = true;
                    axios.get('/albums/latest',
                        {
                            params: { },
                            headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
                        }
                    )
                        .then(response => {
                            this.latestAlbums = response.data;
                        })
                        .catch(error => console.log(error))
                }

                this[TRACK_FETCH](params)
            },
            getAlbumImage(album) {
                return this.album_covers_server + album.label_fk + '/' + album.album_num + ".jpg";
            },

			closeSuggest() {
				this.close_suggest = true;
			},
			suggestClosed() {
				this.close_suggest = false;
			},
			clearSearch() {
                this.filters.search = '';
                this.fetchData();
            },
			formatAsTime(val) {
				let minutes = Math.floor(val / 60);
				let seconds = val - (minutes * 60) ;
				if (minutes>0 && seconds < 10) {
					seconds = '0'+seconds;
				}
				if (minutes < 1) {
					return seconds+' '+this.$t('global.sec');
				}
				if (val>=1199) {
					return this.$t('global.max');
				}
				return minutes+':'+seconds;
			},
			resetBpmLinkControl() {
				if (this.bpm[0] == 0 && this.bpm[1] == 200) {
					$("#reset_bpm_link").css('display', 'none');
				}
				else {
					$("#reset_bpm_link").css('display', 'inline-block');
				}
			},
			addKeyword(word) {
				this.filters.search = this.$refs.search_form.words;
				this.page = 1;
				if (this.filters.search == undefined || this.filters.search =="") {
					this.filters.search = word;
				}
				else {
					this.filters.search += ' '+word;
				}
				this.setURL();
				//this.fetchData();
			},
			excludeKeyword(word) {
				this.filters.search = this.$refs.search_form.words;
				this.page = 1;
				if (this.filters.search == undefined || this.filters.search =="") {
					this.filters.search = word;
				}
				else {
					this.filters.search += ' -'+word;
				}
				this.setURL();
				//this.fetchData();
			},
			getVisible(countVal) {
				if (countVal<1) {
					return "display:none";
				}
				else {
					return '';
				}
			},
			getFileLink(label, album, track) {
				axios.get('/track_download',
						{
							params: {
								l: label.toLowerCase(),
								a: album.toLowerCase(),
								t: track
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						if (response.data.block !== null && response.data.block == 1) {
							alert("This option is disabled");
							return;
						}
                        else {
                            if (response.data.block !== null && response.data.block == 2) {
                                this.showPremiumError();
                                return;
                            }
                        }
						var fileLink = document.createElement('a');
						fileLink.href = response.data.url;
						fileLink.setAttribute('download', response.data.fname+'.mp3');
						document.body.appendChild(fileLink);
						fileLink.click();
					  })
					  .catch(error => console.log(error))
			},

            showPremiumError(err) {
                const ok = this.$refs.confirmDialogue.show({
                    title: this.$t('global.license_for_premium_track_title'),
                    message: this.$t('global.please_contact_us_premium_track'),
                    okButton:'OK'
                });
            },

			showFilter(filterBlock, force) {
				filterBlock = ""+filterBlock;
				if (filterBlock=='' || filterBlock == "undefined" || filterBlock == "null") {
					return false;
				}
				if (this.activeFilter == filterBlock && !force) {
					this.closeFilter();
					return;
				}

				this.activeFilter = filterBlock;
				this.$cookie.set('filter', filterBlock, 60 * 60 * 24 * 360);
				this.setActiveMenu(filterBlock);
				$('.track_list_filter_block_sub_cnt > div').css('display', 'none');
				if (Vue.config.keywordsCache == undefined || Vue.config.reloadKeywords) {
				   axios
						.get('keywords?lang='+Vue.config.lang)
						.then(response => (this.keywords = response.data ));
				}
				else {
					this.keywords = Vue.config.keywordsCache
				}

				if (Vue.config.labelsCache == undefined) {
				   axios
						.get('labels')
						.then(response => (this.labels = response.data ));
				}
				else {
					this.labels = Vue.config.labelsCache
				}


				$('.track_list_filter_block_sub_cnt .track_list_filter_'+filterBlock+'_list').css('display', 'block');

				/*
				 * SLIDERS
				 */
				if (filterBlock == "advanced") {
					///
				}


				$('.track_list_filter_block').css('width', '320px');
				$('.track_list_filter_block_sub').css('display', 'block');
			},
			closeFilter() {
				$('.track_list_filter_block_main li').removeClass('active');
				this.activeFilter = '';
				this.$cookie.set('filter', '', 60 * 60 * 24 * 360);
				$('.track_list_filter_block_sub_cnt > div').css('display', 'none');
				$('.track_list_filter_block').css('width', '115px');
				$('.track_list_filter_block_sub').css('display', 'none');

			},
			setActiveMenu(menuName) {
				menuName = ""+menuName;
				$('.track_list_filter_block_main li').removeClass('active');
				if (!menuName || menuName=="null" || menuName == "" || menuName == "undefined") {
					return false;
				}
				this.$refs['filters_main_'+menuName].classList.value = ['active'];
			},
			openLevel(e) {
				if ($(e.target.parentNode.parentNode).find("ul").hasClass('opened')) {
					$(e.target.parentNode.parentNode.parentNode).find("li > ul").removeClass('opened');
				}
				else {
					$(e.target.parentNode.parentNode.parentNode).find("li > ul").removeClass('opened');
					$(e.target.parentNode.parentNode).find("ul").addClass('opened');
				}
			},
			notEmptyTree(obj) {
				if (obj === undefined || obj.length == 0) {
					return false;
				}
				return true;
			},
			showCurrentTime() {
				let minutesFrom = Math.floor(this.length[0] / 60);
				let secondsFrom = this.length[0] - (minutesFrom * 60) ;
				let minutesTo = Math.floor(this.length[1] / 60);
				let secondsTo = this.length[1] - (minutesTo * 60) ;
				if (secondsFrom < 10) {
					secondsFrom = '0'+secondsFrom;
				}

				if (secondsTo < 10) {
					secondsTo = '0'+secondsTo;
				}

				if (minutesTo >= 20) {
					return minutesFrom+':'+secondsFrom+' - '+this.$t('global.max');
				}
				return minutesFrom+':'+secondsFrom+' - '+minutesTo+':'+secondsTo;
			},
			isActiveOption(val, collection, splitter) {
				if (collection === undefined) {
					return;
				}
				let collectionArray = collection.split(splitter);
				if (collectionArray.includes(""+val)) {
					return 'active-option';
				}
				else if (collectionArray.includes("-"+val)) {
					return 'active-option-excl';
				}
				else {
					return '';
				}
			},
			excludeEra(era) {
				this.page = 1;
				this.filters.era = this.keywordManager(
					this.filters.era,
					era,
					-1
				);
				this.setURL();
				//this.fetchData();
			},
			removeEra(era) {
				this.page = 1;
				this.filters.era = this.keywordManager(
					this.filters.era,
					era,
					0
				);
				this.setURL();
				//this.fetchData();
			},
			switchEra(era) {
				if (this.filters.era === undefined || this.filters.era === "") {
					this.addEra(era);
				}
				else {
					let collectionArray = this.filters.era.split(',');
					if (collectionArray.includes(""+era)) {
						this.removeEra(era);

					}
					else {
						this.addEra(era);

					}
				}
			},
			addEra(era) {
				this.page = 1;
				this.filters.era = this.keywordManager(
					this.filters.era,
					era,
					1
				);
				this.setURL();
				//this.fetchData();
			},
			switchTempo(tempo) {
				if (this.filters.tempo === undefined || this.filters.tempo === "") {
					this.filters.tempo = tempo;
				}
				else {
					if (this.filters.tempo == tempo) {
						this.filters.tempo = "";
					}
					else {
						this.filters.tempo = tempo;
					}
				}
				this.page = 1;
				this.setURL();
				//this.fetchData();
			},
			removeTempo() {
				this.page = 1;
				this.filters.tempo = "";
				this.setURL();
				//this.fetchData();
			},

			removeSimilar() {
				this.similar = "";
				this.setURL();
				//this.fetchData();
			},

			getFavoriteStatus(trackObj) {
				let ftc = VueCookie.get('favorite_tracks');
				let favorite_tracks = [];
				if (ftc!==undefined && ftc!==null && ftc!=="") {
					favorite_tracks = ftc.split(',');
				}

				if (favorite_tracks.indexOf(trackObj.track_id+"")>-1) {
					return 'icon-btn-favorite-active';
				}
				else {
					return 'icon-btn-favorite';
				}
			},
			setFavoriteStatus(trackObj, iconObj) {
				iconObj.currentTarget.className = "icon-btn-favorite-changing";
				axios.get('/user_like_track',
						{
							params: {track_id: trackObj.track_id},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						this.$cookie.set('favorite_tracks', response.data.favorite_tracks);
						$("#track_block_favico_"+response.data.last_change_id).attr('class', this.getFavoriteStatus(trackObj));
					})
					.catch(error => console.log(error))

			},
			/*
			 * action - exclude (-1), include(1), remove(0)
			 */
			keywordManager(list,word,action) {
				var del = ',';
				if (list == undefined) {
					list = "";
				}
				var listArr = list.split(del);
				var newArr = [];
				var added = false;
				for (var i = 0; i<listArr.length; i++) {
					if (listArr[i] !== "") {
						if (listArr[i] == word || listArr[i] == '-'+word) {
							if (action == 1) {
								newArr.push(word);
								added = true;
							}
							else if (action == -1) {
								newArr.push('-'+word);
								added = true;
							}
						}
						else {
							newArr.push(listArr[i]);
						}
					}
				}
				if (!added) {
					if (action == 1) {
						newArr.push(word);
					}
					else if (action == -1) {
						newArr.push('-'+word);
					}
				}
				return newArr.join(del);
			},
			setSearchText (data) {
				this.filters.search = data.text;
				if (data.search_within !== undefined) {
                    let _search_within = "";
                    if (data.search_within.title) {
                        _search_within += "t";
                    }
                    if (data.search_within.description) {
                        _search_within += "d";
                    }
                    if (data.search_within.composer) {
                        _search_within += "c";
                    }
                    this.search_within = _search_within;
                }
				this.sf = null;
				this.setURL();
				//this.fetchData();
			},
			setStems(data) {
				if (!this.enableWatchers) { return; }
				//this.page = 1;
				this.stems = data.text;

				this.setURL();
				//this.fetchData();
			},
			setSearchWithin(data) {
				if (!this.enableWatchers) { return; }
				let _search_within = "";
				if (data.title) {
					_search_within += "t";
				}
				if (data.description) {
					_search_within += "d";
				}
				if (data.composer) {
					_search_within += "c";
				}
				this.search_within = _search_within;
				this.setURL();
				//this.fetchData();
			},
			setURL() {
				let queryVals = [];
				queryVals['search'] = this.filters.search;


				if (this.similar) {
					queryVals['similar'] = this.similar;
				}
				if (this.bpm[0]) {
					queryVals['bpm_min'] = this.bpm[0];
				}
				if (this.bpm[1]) {
					queryVals['bpm_max'] = this.bpm[1];
				}
				if (this.length[0]) {
					queryVals['sec_min'] = this.length[0];
				}
				if (this.length[1]) {
					queryVals['sec_max'] = this.length[1];
				}

				if (this.filters.label) {
					queryVals['label'] = this.filters.label;
				}
				if (this.order) {
					queryVals['order'] = this.order;
				}
				if (this.page) {
					queryVals['page'] = this.page;
				}
				if (this.sort) {
					queryVals['sort'] = this.sort;
				}

				if (this.filters.tempo) {
					queryVals['tempo'] = this.filters.tempo;
				}
				if (this.filters.era) {
					queryVals['era'] = this.filters.era;
				}
				if (this.stems) {
					queryVals['stems'] = 1;
				}
				if (this.search_within) {
					queryVals['search_within'] = this.search_within;
				}
				if (this.sf) {
					queryVals['sf'] = this.sf;
				}


				router.push({ path: 'tracks', query: queryVals}).catch(()=>{});

			},
			addLabel(label) {
				this.page = 1;
				this.filters.label = this.keywordManager(
					this.filters.label,
					label,
					1
				);
				this.setURL();
				//this.fetchData();
			},
			excludeLabel(label) {
				this.page = 1;
				this.filters.label = this.keywordManager(
					this.filters.label,
					label,
					-1
				);
				this.setURL();
				//this.fetchData();
			},
            removeLabel(label) {
				this.page = 1;
				this.filters.label = this.keywordManager(
					this.filters.label,
					label,
					0
				);
				this.setURL();
				//this.fetchData();
			},
			getImgSearchResultInfo() {
				if (this.tracksMeta.from === undefined) {
					return '';
				}
				return this.$t('global.track') + ' <span>' + (this.tracksMeta.from + 1) + '</span> - <span>' + this.tracksMeta.to + '</span>, '+ this.$t('global.results')+': <span>' + this.tracksMeta.total + '</span>';
			},
			getImgUrl(server,label,album) {
				return "background-image:url('"+server+''+label+'/'+album+".jpg')";
			},

			setOrder(data) {
				this.sort = data.sort;
				this.order = data.order;
				this.setURL();
				//this.fetchData();
			},
			showTracksVersions(l,a,t,id) {
				this.$refs['track_cnt_'+id][0].show(l,a,t, 1);
			},
			showTracksStems(l,a,t,id) {
				this.$refs['track_cnt_'+id][0].show(l,a,t, 2);
			},
			showSimilarTracks(track) {
				this.similar = track.track_id;
				this.setURL();
				//this.fetchData();
			},
            cleanListenedTracks() {
			    let currentTime = Date.now() / 1000;
			    let key = "";
                for (let i = 0; i < localStorage.length; i++) {
                    key = localStorage.key(i);
                    if (key.indexOf("track-") > -1) {
                        if (parseInt(localStorage.getItem(key)) < currentTime) {
                            localStorage.removeItem(key);
                        }
                    }
                }
            },
            removeListenedTracks() {
                let key = "";
                for (let i = 0; i < localStorage.length; i++) {
                    key = localStorage.key(i);
                    if (key.indexOf("rack-") > -1) {
                        localStorage.removeItem(key);
                    }
                }
                this.showClearListenedButton = false;
                $( "div.track_common_block" ).each(function() {
                    $( this ).css('opacity', 1);
                });
            },
            isPlackPlayed(trackObj) {
                let isRecord = localStorage.getItem("track-"+trackObj.track_id);
                if (isRecord == null) {
                    return;
                }
                if (isRecord < Math.floor(Date.now() / 1000)) {
                    localStorage.removeItem("track-"+trackObj.track_id);
                    return;
                }
                return "opacity: 0.6";
            },
            isPremiumTrack(trackObj) {
                if (trackObj?.premium) {
                    return "track_common_block track_special_block";
                }
                return "track_common_block";
            },

			playTrack(trackObj) {
				initialPlayer();
				let label = trackObj.label_fk;
				let album = trackObj.album_num;
				let track = trackObj.track_num;
				// Запоминаем, что трек проигран
                localStorage.setItem("track-"+trackObj.track_id, 3 * 24 * 60 * Math.floor(Date.now() / 1000));
                this.showClearListenedButton = true;
                // Сделаем сразу прозрачным на 0.7
                if (this.previous_track !== null) {
                    $("#track_block_"+this.previous_track.track_id).css('opacity', 0.7);
                }
				if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.track_id) {
					if ($("#jquery_jplayer_obj").data('isPlaying')) {
						$("#jquery_jplayer_obj").jPlayer("pause");
					}
					else {
						$("#jquery_jplayer_obj").jPlayer("play");
					}
				}
				else {
					setPlayerData(trackObj, this.album_covers_server);
					$("#jquery_jplayer_obj").jPlayer("setMedia", {
						mp3: this.music_server + 'stream_music_file.php?label='+label.toLowerCase()+'&album='+album.toLowerCase()+'&track='+track,
					}).jPlayer("play");
                    this.previous_track = trackObj;
				}
			},
			addTrackToProjectAction(data) {
				axios.get('/add_to_project',
						{
							params: data,
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
					})
					.catch(error => console.log(error))

				this.formVisible = false
                this.fetchData()
			},
			addToProject(track,eventIcon) {
				this.formTitle = 'Add to Project';
                this.formData = Object.assign({}, {
					track_id: track.track_id,
				});
                this.formVisible = true;
			},



        }
    }
</script>
