<template>
    <section id="shared_playlist">
        <div class="container-fluid">

					<button class="btn-standart right" @click="downloadProject($event)" ref="downloadBtn"><i class="el-icon el-icon-download"></i> {{ $t('project.download_project') }}</button>

					<h1>{{ $t('global.project') }} "{{ project.title }}"</h1>
					<p>{{ project.description }}</p>



					<div class="tracks-list-header-block">
						<div class="col-icon"></div>
						<div>{{ $t('track.title') }}</div>
						<div class="col-bpm">{{ $t('track.bpm') }}</div>
						<div class="col-time">{{ $t('track.time') }}</div>
						<div class="col-download">&nbsp;&nbsp;&nbsp;</div>

					</div>

					<div>
						<div v-for="track in project.tracks" :key="track.track_id" v-bind:id="'track_block_' + track.track_id" v-bind:data-tid="track.track_id" :class="isPremiumTrack(track)">
							<div class="track-block">
								<div class="track-block_icon" v-bind:id="'track_play_btn_' + track.track_id" v-on:click="playTrack(track)" :style="getImgUrl(config.albumCoversServer, track.label_fk, track.album_num)"></div>
								<div class="track-block_title" v-on:click="playTrack(track)">
									<strong>{{ track.primary_title }} <span>{{ track.composers }}</span></strong>
									<p>{{ track.description }}</p>
									<p class="dates_secondary_info">
										<span><strong>{{ $t('track.added') }}:</strong> {{ track.created }}</span>
										<template v-if="track.bpm>0">
											<span><strong>{{ $t('track.bpm') }}:</strong> {{ track.bpm }}</span>
										</template>
										<template v-if="track.tempo">
											<span><strong>{{ $t('track.tempo') }}:</strong> {{ track.tempo }}</span>
										</template>
									</p>
								</div>
								<div class="track-block_versions">
									&nbsp;&nbsp;&nbsp;
								</div>
								<div class="track-block_stems">
									&nbsp;&nbsp;&nbsp;
								</div>
								<div class="track-block_bpm">{{ track.bpm }}</div>
								<div class="track-block_time">{{ track.duration }}</div>



								<div class="track-block_ico hint-top-middle" :data-hint="$t('global.favorite')">
									<template v-if="$auth.check()">
										<i v-on:click="setFavoriteStatus(track, $event)" v-bind:id="'track_block_favico_' + track.track_id" :class="getFavoriteStatus(track)"></i>
									</template>&nbsp;
								</div>
								<div class="track-block_download">
									<i v-on:click="getFileLink(track.label_fk, track.album_num, track.track_num)" target="_blank" class="icon-btn-download"></i>
								</div>





							</div>
						</div>
					</div>



		</div>
    </section>
</template>

<script>
	import Vue from 'vue';
	import axios from 'axios';
    import projectApi from "../api"
	import router from '../../../bootstrap/router';
	import VueCookie from 'vue-cookie';
	Vue.use(VueCookie)

    export default {
        name: "ProjectView",
        components: { },
        data() {
            return {
				userName: '',
                project: {},
				lang: Vue.config.lang,
				music_server: config.musicServer,
				album_covers_server: config.albumCoversServer,
            }
        },
		created() {
			this.userName = this.$cookie.get('firstname');
			if (this.userName.length > 13) {
				this.userName = this.userName.substring(0, 13) + "...";
			}
        },
        methods: {
            async fetchProject() {
                const response = await projectApi.shared(this.$route.params.key,this.$route.params.share)
                this.project = response.data
            },
			getImgUrl(server,label,album) {
				return "background-image:url('"+server+''+label.toLowerCase()+'/'+album.toLowerCase()+".jpg')";
			},
			removeFromProject(trackObj, iconObj) {
				axios.get('/remove_from_project',
						{
							params: {track_id: trackObj.track_id, project_id: this.project.id},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						this.fetchProject();
					})
					.catch(error => console.log(error));

			},
			getFavoriteStatus(trackObj) {
				let ftc = VueCookie.get('favorite_tracks');
				let favorite_tracks = [];
				if (ftc!==undefined && ftc!==null && ftc!=="") {
					favorite_tracks = ftc.split(',');
				}

				if (favorite_tracks.indexOf(trackObj.track_id+"")>-1) {
					return 'icon-btn-favorite-active';
				}
				else {
					return 'icon-btn-favorite';
				}
			},
			setFavoriteStatus(trackObj, iconObj) {
				iconObj.currentTarget.className = "icon-btn-favorite-changing";
				axios.get('/user_like_track',
						{
							params: {track_id: trackObj.track_id},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						this.$cookie.set('favorite_tracks', response.data.favorite_tracks);
						$("#track_block_favico_"+response.data.last_change_id).attr('class', this.getFavoriteStatus(trackObj));
					})
					.catch(error => console.log(error))

			},
			playTrack(trackObj) {
				initialPlayer();
				let label = trackObj.label_fk;
				let album = trackObj.album_num;
				let track = trackObj.track_num;

				if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.track_id) {
					if ($("#jquery_jplayer_obj").data('isPlaying')) {
						$("#jquery_jplayer_obj").jPlayer("pause");
					}
					else {
						$("#jquery_jplayer_obj").jPlayer("play");
					}
				}
				else {
					setPlayerData(trackObj, this.album_covers_server);
					$("#jquery_jplayer_obj").jPlayer("setMedia", {
						mp3: this.music_server + 'stream_music_file.php?label='+label.toLowerCase()+'&album='+album.toLowerCase()+'&track='+track,
					}).jPlayer("play");
				}
			},
            isPremiumTrack(trackObj) {
                if (trackObj?.premium) {
                    return "track_common_block track_special_block";
                }
                return "track_common_block";
            },
			getFileLink(label, album, track) {
				axios.get('/shared_track_download',
						{
							params: {
								l: label.toLowerCase(),
								a: album.toLowerCase(),
								t: track,
								key: this.$route.params.key,
								share: this.$route.params.share
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
                        if (response.data.block !== null && response.data.block == 1) {
                            alert("This option is disabled");
                            return;
                        }
                        else {
                            if (response.data.block !== null && response.data.block == 2) {
                                alert("Download link is expired");
                                return;
                            }
                        }
						var fileLink = document.createElement('a');
						fileLink.href = response.data.url;
						fileLink.setAttribute('download', response.data.fname+'.mp3');
						document.body.appendChild(fileLink);
						fileLink.click();
					  })
					  .catch(error => console.log(error))


			},
			resetDownloadButton() {
				this.$refs.downloadBtn.innerHTML = '<i class="el-icon el-icon-download"></i> '+this.$t('project.download_project');
			},
			downloadProject(buttonObj) {
				buttonObj.currentTarget.innerHTML = '<i class="el-icon el-icon-download"></i> '+this.$t('global.loading');
				axios.get('/shared_project_download',
						{
							params: {
								key: this.$route.params.key,
								share: this.$route.params.share
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						if (response.data.error !== undefined) {
							this.showError(response.data.error);
						}
						else {
							this.resetDownloadButton();
							var fileLink = document.createElement('a');
							fileLink.href = response.data;
							fileLink.setAttribute('download', response.data);
							document.body.appendChild(fileLink);
							fileLink.click();
						}

					  })
					  .catch(error => console.log(error))
			},




			showError(err) {
				const ok = this.$refs.confirmDialogue.show({
					title: this.$t('global.error'),
					message: this.$t('project.errors.'+err),
				});
			}
        },
        mounted() {
            this.fetchProject()
        },
    }
</script>

<style lang="scss" scoped>
	#shared_playlist {
		margin-top: 28px;
		min-height: calc(100vh - 53px);
		display: flex;
		padding-bottom: 120px;
		padding-top: 20px;
	}
	.dates_secondary_info {
		padding-top: 4px;
		border-top: 1px solid rgba(255,255,255,0.2);
		span {
			display: inline-block;
			padding-right: 4px;
			strong {
				display: inline-block;
			}
			&::after {
				display: inline-block;
				padding-left: 8px;
				content: "|";
				opacity: 0.3;
			}
			&:last-child {
				&::after {
					display:none;
				}
			}
		}
	}
	.lab_alb_trck span {
		opacity: 0.4;
		position: relative;
	}
	.lab_alb_trck a {
		position: relative;
		border-radius: 4px;
		padding: 0 3px;
		&:hover {
			background:#000;
		}
	}

</style>
