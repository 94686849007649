<template>
    <div v-bind:id="'track_block_' + track.track_id" v-bind:data-tid="track.track_id" :class="isPremiumTrack(track)">
		<div class="track-block">
			<div class="track-block_icon" v-bind:id="'track_play_btn_' + track.track_id" v-on:click="playTrack(track)" :style="getImgUrl(track)"></div>
			<div class="track-block_title">
				<strong v-on:click="playTrack(track)">{{ track.primary_title }} <span>{{ track.composers }}</span></strong>
				<p>{{ track.description }}</p>
				<p class="lab_alb_trck">
					<a target="_blank" :href="'/albums?label='+track.label_fk.toUpperCase()+'&page=1'" class="hint-top-middle" :data-hint="$t('global.all_albums_of_this_labels')">{{ $t('global.label') }}: {{ track.label_fk.toUpperCase() }}</a>
					<span>/</span>
					<a target="_blank" :href="'/albums/'+track.label_fk+'/'+track.album_num" class="hint-top-middle" :data-hint="$t('global.open_album')">{{ $t('global.album') }}: {{ track.album_num.toUpperCase() }}</a>
					<span>/</span>
					<a target="_blank" :href="'/tracks/'+track.label_fk+'/'+track.album_num+'/'+track.track_num"  class="hint-top-middle" :data-hint="$t('global.open_track')">{{ $t('global.track') }}: {{ track.track_num }}</a>
				</p>
				<p class="dates_secondary_info">
					<span><strong>{{ $t('track.added') }}:</strong> {{ track.created }}</span>
					<template v-if="track.bpm>0">
						<span><strong>{{ $t('track.bpm') }}:</strong> {{ track.bpm }}</span>
					</template>
					<template v-if="track.tempo">
						<span><strong>{{ $t('track.tempo') }}:</strong> {{ track.tempo }}</span>
					</template>
					<template v-if="track.downloaded">
						<span><strong>{{ $t('track.downloaded') }}:</strong> {{ track.downloaded }}</span>
					</template>

				</p>
			</div>
			<template v-if="track.bpm>0">
				<div class="track-block_bpm">{{ track.bpm }}</div>
			</template>
			<template v-else>
				<div class="track-block_bpm">&nbsp;</div>
			</template>
			<div class="track-block_time">{{ track.duration }}</div>

			<div class="track-block_ico hint-top-middle" data-hint="Add to Project">
				<template v-if="$auth.check()">
					<i v-on:click="addToProject(track, $event)" class="icon-btn-add-to-proj"></i>
				</template>&nbsp;
			</div>


			<div class="track-block_ico hint-top-middle" data-hint="Find similar">
				<a v-bind:href="'/tracks?similar='+track.track_id" target="_blank"><i class="icon-btn-similar" style="margin-top: 6px;"></i></a>
			</div>

			<div class="track-block_ico hint-top-middle" :data-hint="$t('global.favorite')">
				<template v-if="$auth.check()">
					<i v-on:click="setFavoriteStatus(track, $event)" v-bind:id="'track_block_favico_' + track.track_id" :class="getFavoriteStatus(track)"></i>
				</template>&nbsp;
			</div>
			<div class="track-block_ico">
				<i v-on:click="getFileLink(track)" target="_blank" class="icon-btn-download"></i>
			</div>



		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import axios from 'axios';
	import VueCookie from 'vue-cookie'
    import ConfirmDialogue from "./ConfirmDialogue.vue";
	Vue.use(VueCookie)
    export default {
        components: {ConfirmDialogue},
		props: ['track'],
        name: "TrackBlock",
        data: () => ({
        }),
		methods: {
			getFavoriteStatus(trackObj) {
				let ftc = VueCookie.get('favorite_tracks');
				let favorite_tracks = [];
				if (ftc!==undefined && ftc!==null && ftc!=="") {
					favorite_tracks = ftc.split(',');
				}

				if (favorite_tracks.indexOf(trackObj.track_id+"")>-1) {
					return 'icon-btn-favorite-active';
				}
				else {
					return 'icon-btn-favorite';
				}
			},
			setFavoriteStatus(trackObj, iconObj) {
				iconObj.currentTarget.className = "icon-btn-favorite-changing";
				axios.get('/user_like_track',
						{
							params: {track_id: trackObj.track_id},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
						this.$cookie.set('favorite_tracks', response.data.favorite_tracks);
						$("#track_block_favico_"+response.data.last_change_id).attr('class', this.getFavoriteStatus(trackObj));
					})
					.catch(error => console.log(error))

			},
			playTrack(trackObj) {
				initialPlayer();
				let label = trackObj.label_fk;
				let album = trackObj.album_num;
				let track = trackObj.track_num;

				if ($("#jquery_jplayer_obj").data('play-track-id') == trackObj.track_id) {
					if ($("#jquery_jplayer_obj").data('isPlaying')) {
						$("#jquery_jplayer_obj").jPlayer("pause");
					}
					else {
						$("#jquery_jplayer_obj").jPlayer("play");
					}
				}
				else {
					setPlayerData(trackObj, config.albumCoversServer);
					$("#jquery_jplayer_obj").jPlayer("setMedia", {
						mp3: config.musicServer + 'stream_music_file.php?label='+label.toLowerCase()+'&album='+album.toLowerCase()+'&track='+track,
					}).jPlayer("play");
				}
			},
			getImgUrl(trackObj) {
				return "background-image:url('"+config.albumCoversServer+''+trackObj.label_fk.toLowerCase()+'/'+trackObj.album_num.toLowerCase()+".jpg')";
			},
            isPremiumTrack(trackObj) {
                if (trackObj?.premium) {
                    return "track_common_block track_special_block";
                }
                return "track_common_block";
            },
			getFileLink(trackObj) {
				let label = trackObj.label_fk;
				let album = trackObj.album_num;
				let track = trackObj.track_num;
				axios.get('/track_download',
						{
							params: {
								l: label.toLowerCase(),
								a: album.toLowerCase(),
								t: track
							},
							headers: {"Authorization" : 'Bearer '+window.localStorage.getItem('auth_token_default')},
						}
					)
					.then(response => {
                        if (response.data.block !== null && response.data.block == 1) {
                            alert("This option is disabled");
                            return;
                        }
                        else {
                            if (response.data.block !== null && response.data.block == 2) {
                                alert("Download link is expired");
                                return;
                            }
                        }
						var fileLink = document.createElement('a');
						fileLink.href = response.data.url;
						fileLink.setAttribute('download', response.data.fname+'.mp3');
						document.body.appendChild(fileLink);
						fileLink.click();
					  })
					  .catch(error => console.log(error))
			},
			addToProject(track,eventIcon) {
				this.$emit('addToProject', track)
			},

		}
    }
</script>
<style lang="scss" scoped>


	.dates_secondary_info {
		padding-top: 4px;
		border-top: 1px solid rgba(255,255,255,0.2);
		span {
			display: inline-block;
			padding-right: 4px;
			strong {
				display: inline-block;
			}
			&::after {
				display: inline-block;
				padding-left: 8px;
				content: "|";
				opacity: 0.3;
			}
			&:last-child {
				&::after {
					display:none;
				}
			}
		}
	}
	.lab_alb_trck span {
		opacity: 0.4;
		position: relative;
	}
	.lab_alb_trck a {
		position: relative;
		border-radius: 4px;
		padding: 0 3px;
		&:hover {
			background:#000;
		}
	}
</style>
